import React from "react";
import Link from "@Components/Link";
import styles from "./HornorBrand.module.scss";

interface MenuItem {
   title: string;
   iconDesktop: string;
   iconMobile: string;
   path: string;
}

const MENU_ITEMS = [
   {
      title: "Lotte Accelerator",
      iconDesktop: "/assets/img/home/lotteaccelerator.png",
      iconMobile: "/assets/img/home/lotteaccelerator-mb.png",
      path: "https://lotteaccelerator.com",
   },
   {
      title: "Tech in Asia",
      iconDesktop: "/assets/img/home/technasia.png",
      iconMobile: "/assets/img/home/technasia-mb.png",
      path: "https://www.techinasia.com",
   },
   {
      title: "Google",
      iconDesktop: "/assets/img/home/google.png",
      iconMobile: "/assets/img/home/google-mb.png",
      path: "https://www.google.com",
   },
];

const HornorBrand = () => {
   const menus: MenuItem[] = MENU_ITEMS;

   return (
      <div className={styles["hornor-brand"]}>
         <div className={styles["container"]}>
            <h2 className={styles["section-title"]}>Space T tự hào được vinh danh tại</h2>
            <div className="row gx-2 gy-3 gx-md-3">
               {menus.map(({ title, iconMobile, iconDesktop, path }, index) => (
                  <div className="col-4" key={path || index}>
                     <Link target="_blank" rel="noopener noreferrer">
                        <picture>
                           <source media="(min-width: 768px)" srcSet={iconDesktop} />
                           <source media="(max-width: 767px)" srcSet={iconMobile} />
                           <img title={title} alt={title} src={iconMobile} />
                        </picture>
                     </Link>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default HornorBrand;
