const SkeletonLoader = () => {
   return (
      <div
         className="d-flex flex-column justify-content-center align-items-center"
         style={{ height: "100%" }}
      >
         <img
            style={{ width: "80px", height: "80px" }}
            src="/assets/img/empty/construction.png"
            alt=""
         />
         <p className="fs-13 mt-3">
            Không tìm thấy hình ảnh, vui lòng chọn khu vực khác.
         </p>
      </div>
   );
};

export default SkeletonLoader;
