import { isServer } from "utils";

export const log = (...args: any) => {
   if (process.env.NODE_ENV === "development") {
   }
};
export const logg = (...args: any) => {
   if (process.env.NODE_ENV === "development") {
   }
};
export const logserver = (...args: any) => {
   if (process.env.NODE_ENV === "development" && isServer) {
   }
};
export const logclient = (...args: any) => {
   if (process.env.NODE_ENV === "development" && !isServer) {
   }
};
