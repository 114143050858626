import Link from "next/link";
import DuAnHoanThienContent, { ISSRData } from "@Modules/homev2/components/du-an-hoan-thien/DuAnHoanThienContent";
import styles from "./DuAnHoanThienWrap.module.scss";

interface Props {
   dataBySlug?: ISSRData;
   total?: number;
}

const DuAnHoanThienWrap: React.FC<Props> = ({ dataBySlug, total }) => {
   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["title"]}>Dự án hoàn thiện</h2>
            <Link href="/thu-vien-thiet-ke" className={styles["view-all"]}>
               <span className={styles["viewall-text"]}>Xem tất cả </span>
               <span className={styles["next-icon"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M9 6L15 12L9 18"
                        stroke="#FF6347"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </span>
            </Link>
         </div>
         <div className={styles["sub-title"]}>{`Tham khảo ${total || 0} mẫu thiết kế thi công nội thất đẹp`}</div>
         <DuAnHoanThienContent dataBySlug={dataBySlug} />
      </div>
   );
};

export default DuAnHoanThienWrap;
