import React from "react";
import Image from "next/image";
import Link from "next/link";
import { IMenuItem } from "@Models/menuItem.model";

interface IState {}

interface IProps {
   categories: IMenuItem[];
}

export default class ProductCategory extends React.Component<IProps, IState> {
   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      return (
         <>
            <section className="product-category ">
               <div className="product-category-item">
                  <div className="home-catalog-list">
                     {[...this.props.categories, ...new Array(9)]
                        .slice(0, 8)
                        .map((item, index) => {
                           if (!item) {
                              return (
                                 <a
                                    key={index}
                                    id="category-item"
                                    className="text-decoration-none catalog-item "
                                 />
                              );
                           }
                           return (
                              <Link
                                 id="category-item"
                                 href={`/shop/${item.info.slug || ""}`}
                                 className="text-decoration-none catalog-item "
                                 key={index}
                              >
                                 <div className="img">
                                    <Image
                                       width={65}
                                       height={65}
                                       src={item.__icon || ""}
                                       alt={item.info.title}
                                    />
                                    <div className="cover"></div>
                                 </div>
                                 <h3 className="catalog-item__name">
                                    {item.info.title}
                                 </h3>
                              </Link>
                           );
                        })}
                  </div>
               </div>
            </section>
         </>
      );
   }
}
