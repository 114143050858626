import Menu from "@Models/menu.model";
import MenuItem from "@Models/menuItem.model";

export function getMainCategorys(shopMenus: MenuItem[]) {
   function sortByValues(arrs, values) {
      return values.map((value) => arrs.find((it) => it.info.slug === value));
   }

   const keys = [
      "noi-that",
      "ghe-van-phong",
      "ban-lam-viec",
      "tu-quan-ao",
      "giuong",
      "sofa",
      "do-trang-tri",
      "do-gia-dung",
   ];
   const shopCategories = sortByValues(
      Menu.findMultilItemWithSlugRecursive(shopMenus, keys),
      keys
   );
   return shopCategories as MenuItem[];
}
