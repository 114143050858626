import Link from "next/link";
import React from "react";
import Carousel from "react-slick";
import styles from "./MainCategory.module.scss";

interface MenuItem {
   title: string;
   icon: string;
   iconLg: string;
   path: string;
}

type Props = {
   menus: MenuItem[];
};

const MainCategory: React.FC<Props> = ({ menus = [] }) => {
   return (
      <>
         {/* Desktop */}
         <div className={styles["main-category"]}>
            <div className={styles["st-container"]}>
               <div className="row gx-1 gy-3 d-none d-md-flex">
                  {menus.map((item, index) => (
                     <div className="col-3 col-md" key={index}>
                        <Link className={styles["menu-item"]} href={item.path}>
                           <div className={styles["image"]}>
                              <picture>
                                 <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                                 <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                                 <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                              </picture>
                              <div className={styles["cover"]}></div>
                           </div>
                           <div className={styles["title"]}>{item.title}</div>
                        </Link>
                     </div>
                  ))}
               </div>
            </div>
         </div>

         {/* Mobile */}
         <div className="main-category">
            <div className="st-container">
               <div className="carowsel-mb px-12 d-block d-md-none">
                  <Carousel dots arrows={false} slidesToShow={4.5} slidesToScroll={4.5} infinite={false}>
                     {menus.map((item, index) => (
                        <div className="col-3 col-md" key={index}>
                           <Link className="menu-item" href={item.path}>
                              <div className="image">
                                 <picture>
                                    <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                                    <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                                    <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                                 </picture>
                                 <div className="cover"></div>
                              </div>

                              <span>{item.title}</span>
                           </Link>
                        </div>
                     ))}
                  </Carousel>
               </div>
            </div>
         </div>
      </>
   );
};

export default MainCategory;
