import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import CardProductV2 from "@Components/CardProductV2";
import { Paginate } from "../../../models/model";
import Bookmark from "../../../components/modal/Bookmark";
import Product, { IProduct } from "../../../models/product.model";
import { IUserBookmark } from "../../../models/userBookmark.model";

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 6,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
   responsive: [
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
         },
      },
   ],
};

interface IProps {
   className?: string;
   userBookmarks: IUserBookmark[];
   products?: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks?: IUserBookmark[];
   };
   handleChangeUserBookmark?: (action: "create" | "delete", userBookmark: IUserBookmark) => void;
}

const BestSeller: React.FC<IProps> = ({ userBookmarks, products, className }) => {
   const [userBookmarksState, setUserBookmarks] = useState<IUserBookmark[] | undefined>(userBookmarks);
   const [notification, setNotification] = useState<{
      code: number;
      show: boolean;
   }>();

   useEffect(() => {
      setUserBookmarks(userBookmarks);
   }, [userBookmarks]);

   const handleChangeNotification = (data: { code: number; show: boolean }) => {
      setNotification(data);
   };

   return (
      <section className={classNames("product-horizontal", className)}>
         {!products ? (
            <div className="product-list">
               <div className="row gx-3">
                  {[...Array(4)].map((_element, i) => (
                     <div className="col" key={i}>
                        <Skeleton animation="wave" width="100%" height={170} />
                     </div>
                  ))}
               </div>
            </div>
         ) : (
            <Slider {...settingsSlider} className="product-slider">
               {products?.paginate?.docs?.map((product, _index: number) => {
                  const bookmark = userBookmarksState?.find((bookmark) => bookmark?.info?.openId === product._id);
                  return (
                     <div className="slider-item" key={product._id}>
                        <CardProductV2
                           showDes
                           fixedHeight
                           product={product}
                           bookmark={bookmark}
                           onChangeNotification={(noti) => handleChangeNotification(noti)}
                        />
                     </div>
                  );
               })}
            </Slider>
         )}
         <Bookmark show={notification?.show} notification={notification?.code} handleClose={() => handleChangeNotification({ code: 0, show: false })} />
      </section>
   );
};

export default BestSeller;
