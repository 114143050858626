import Link from "next/link";
import HinhAnhNoiThatContent, {
   IData as IDataHinhAnhNoiThat,
} from "@Modules/homev2/components/hinh-anh-noi-that/HinhAnhNoiThatContent";
import { TListRoom } from "@Models/keyword.model";
import styles from "./HinhAnhNoiThatWrap.module.scss";

interface Props {
   categories?: TListRoom;
   dataBySlug?: {
      docs?: IDataHinhAnhNoiThat[];
   };
   datas?: {
      docs?: IDataHinhAnhNoiThat[];
      totalDocs?: number;
   };
}

const HinhAnhNoiThatWrap: React.FC<Props> = ({ categories, dataBySlug, datas }) => {
   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["title"]}>Hình ảnh nội thất</h2>
            <Link href="/thu-vien-anh" className={styles["view-all"]}>
               <span className={styles["viewall-text"]}>Xem tất cả </span>
               <span className={styles["next-icon"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M9 6L15 12L9 18"
                        stroke="#FF6347"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </span>
            </Link>
         </div>
         <div
            className={styles["sub-title"]}
         >{`Xem qua ${datas?.totalDocs} ý tưởng và liên hệ Space T để nhận tư vấn nhé`}</div>
         <HinhAnhNoiThatContent categories={categories} dataBySlug={dataBySlug} />
      </div>
   );
};

export default HinhAnhNoiThatWrap;
