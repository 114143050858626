import Link from "next/link";
import SocialFeed, { ISocialFeed } from "../../../models/socialFeed.model";
import { Paginate } from "../../../models/model";
import SocialFeeds from "./SocialFeed";

type Props = {
   datas: Paginate<SocialFeed, ISocialFeed>;
};

const BaiVietNoiBat: React.FC<Props> = ({ datas }) => {
   return (
      <div className="block" style={{ paddingBottom: "10px" }}>
         <div className="block-sub ">
            <h2 className="title">Bài viết nổi bật</h2>
            <Link title="Social Feed" href="/feed" className="view-all">
               <span className="viewall-text">Xem tất cả </span>
               <span className="next-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M9 6L15 12L9 18"
                        stroke="#FF6347"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </span>
            </Link>
         </div>
         <div className="sub-title">Mạng xã hội cho cộng đồng yêu nhà</div>
         <SocialFeeds socialFeeds={datas} />
      </div>
   );
};

export default BaiVietNoiBat;
