import React, { useRef, useState, useMemo, useEffect } from "react";
import Link from "next/link";
import Slider, { Settings } from "react-slick";
import classnames from "classnames";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import { projectType_v2 } from "@Models/keyword.model";
import ActualWork, { EActualWorkTypes } from "@Models/actualWork.model";
import SkeletonLoader from "@Components/skeleton-loader";
import Loadingv2 from "@Components/Loading/v2";
import { ImageView } from "../../../../components/image";
import styles from "./DuAnHoanThienContent.module.scss";

// *** datas
export interface ISSRData {
   paginate: Paginate;
   userBookmarks: any[];
}

export interface Paginate {
   totalDocs: number;
   totalPages: number;
   totalRefDocs: number;
   currentPage: number;
   docs: Doc[];
   previousPage: any;
   nextPage: number;
   limit: number;
}

export interface Doc {
   $createdAt_DDMMYYYY: string;
   _id: string;
   info: Info;
   options: Options;
   partnerId?: string;
   mediaId: string;
   styleIds: string[];
   keywordIds: any[];
   groupIds: any[];
   createdAt: string;
   locationId: string;
   sortPoint: string;
   __bookmarkCount: number;
   __media: Media;
   __partner?: Partner;
}

export interface Info {
   title: string;
   slug: string;
   acreage: number;
   price: any;
   imageFeatured: any;
   typeOfDesign: string;
   completionStatus: string;
   viewCount: number;
   bookmarkCount: number;
   typeOfConstruction: string;
}

export interface Options {
   status: string;
   articleType: string;
}

export interface Media {
   $createdAt_DDMMYYYY: any;
   _id: string;
   info: Info2;
   options: Options2;
   $url: string;
   $url64ResizeMax: string;
   $url5And6ColResizeMax: string;
   $url3And4ColResizeMax: string;
   $url2ColResizeMax: string;
   $url1ColResizeMax: string;
}

export interface Info2 {
   container: string;
   domain: string;
   originalName: string;
   protocol: string;
   size: number;
   fileType: string;
   extension: string;
   position: number;
   isAvatar?: boolean;
   viewCount?: number;
}

export interface Options2 {
   status: string;
   storageProvider: string;
}

export interface Partner {
   $createdAt_DDMMYYYY: any;
   _id: string;
   info: Info3;
   options: Options3;
}

export interface Info3 {
   name: string;
   slug: string;
   location: string;
   imageFeatured?: string;
   thumbnail?: string;
}

export interface Options3 {
   status: string;
   publicMode: string;
   isVerified: string;
}
// * finish datas

interface IProps {
   keywordGroupsByType?: {
      [type: string]: {
         _id: string;
         info: { slug: string; content: string };
      }[];
   };
   dataBySlug?: ISSRData;
}
const DuAnHoanThienContent: React.FC<IProps> = ({ dataBySlug }) => {
   const sliderRef = useRef<Slider>(null);
   const [slug, setSlug] = useState<string>("can-ho-chung-cu");
   const [datas, setDatas] = useState<Doc[]>(dataBySlug?.paginate?.docs || []);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const router = useRouter();
   const [isClient, setIsClient] = useState<boolean>(false);

   useEffect(() => {
      setIsClient(true);
   }, []);

   const fetchData = async (slug?: string) => {
      setIsLoading(true);
      try {
         const result = await ActualWork.getPaginate({
            limit: 4,
            articleType: EActualWorkTypes.designLibrary,
            url: slug,
         });
         setDatas((result as any)?.paginate?.docs || []);
      } catch (error) {
         console.error("Failed to fetch data:", error);
      } finally {
         setIsLoading(false);
      }
   };

   const carouselSettings: Settings = useMemo(
      () => ({
         dots: false,
         arrows: false,
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 4,
         infinite: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  slidesToShow: 3.5,
                  slidesToScroll: 3,
               },
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 2,
               },
            },
         ],
      }),
      []
   );

   const handleFilterClick = (newSlug: string) => {
      sliderRef.current?.slickGoTo(0);
      setSlug(newSlug);
      fetchData(newSlug);
   };

   const renderSliderItems = () => {
      return datas?.map((item: Doc) => (
         <div key={item._id} className={styles["slider-item"]}>
            <Link
               style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}}
               title={item.info.title}
               href={`/thu-vien-thiet-ke/${item.info.slug}`}
               className="text-decoration-none"
            >
               <div className={styles["img"]}>
                  <ImageView
                     objectFit="cover"
                     src={item.__media?.$url3And4ColResizeMax || item.__media?.$url || ""}
                     alt={item.info.title}
                     className={styles["type-space-item_image"]}
                  />
               </div>
               <div className={styles["article-item-info"]}>
                  <div className={styles["truncate-line-2"]}>{item.info.title}</div>
               </div>
            </Link>
         </div>
      ));
   };

   const baseStyle = {
      display: datas.length === 0 || isLoading ? "flex" : "block",
      justifyContent: datas.length === 0 || isLoading ? "center" : "unset",
      alignItems: datas.length === 0 || isLoading ? "center" : "unset",
   };

   const style = {
      ...baseStyle,
      height: isMobile ? "158px" : "256px",
   };

   const renderContent = () => {
      if (isClient && isLoading) {
         return <Loadingv2 />;
      }

      if (isClient && datas.length === 0) {
         return <SkeletonLoader />;
      }

      return (
         <Slider {...carouselSettings} ref={sliderRef}>
            {renderSliderItems()}
         </Slider>
      );
   };

   return (
      <section className={styles["construction-design"]}>
         <div className={styles["filter-cate"]}>
            {projectType_v2?.map((item) => (
               <div
                  key={item.title}
                  className={classnames(styles["filter-item"], {
                     [styles["active"]]: slug === item.slug,
                  })}
                  onClick={() => handleFilterClick(item.slug)}
               >
                  {item.title}
               </div>
            ))}
         </div>
         <div style={style}>{renderContent()}</div>
      </section>
   );
};

export default DuAnHoanThienContent;
