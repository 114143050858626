import { useEffect, useState } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import Brand from "@Models/brand.model";
import MenuItem from "@Models/menuItem.model";
import Link from "@Components/Link";
import styles from "./index.module.scss";

const KEYWORDS = [
   { name: "thiết kế nội thất", slug: "/blog/thiet-ke-noi-that" },
   { name: "thi công nội thất", slug: "/blog/thi-cong-noi-that" },
   { name: "mẫu phòng ngủ đẹp", slug: "/thu-vien-anh/mau-phong-ngu" },
   { name: "mẫu phòng khách đẹp", slug: "/thu-vien-anh/mau-phong-khach" },
   { name: "mẫu phòng ăn", slug: "/thu-vien-anh/mau-phong-an" },
   { name: "mẫu phòng làm việc", slug: "/thu-vien-anh/mau-phong-lam-viec" },
   { name: "mẫu phòng cho bé", slug: "/thu-vien-anh/mau-phong-cho-be" },
   { name: "mẫu phòng tắm", slug: "/thu-vien-anh/mau-phong-tam" },
   { name: "mẫu phòng bếp", slug: "/thu-vien-anh/mau-phong-bep" },
   { name: "mẫu lối vào", slug: "/thu-vien-anh/mau-loi-vao" },
   { name: "mẫu lô gia", slug: "/thu-vien-anh/mau-lo-gia" },
   { name: "thiết kế nhà online", slug: "/thiet-ke-2d-3d" },
   {
      name: "chung cư 3 phòng ngủ",
      slug: "/blog/nguon-cam-hung/top-15-y-tuong-thiet-ke-noi-that-chung-cu-3-phong-ngu-doc-dao-nhat-2023",
   },
   {
      name: "chung cư 2 phòng ngủ",
      slug: "/blog/nguon-cam-hung/15-y-tuong-thiet-ke-noi-that-chung-cu-2-phong-ngu-moi-la-theo-xu-huong-2023",
   },
   {
      name: "mẫu nhà phố đẹp",
      slug: "/blog/nguon-cam-hung/top-29-mau-nha-pho-dep-hien-dai-duoc-tim-kiem-nhieu-nhat-2023",
   },
   {
      name: "mẫu nhà mái Thái",
      slug: "/blog/kien-thuc-noi-that/nha-mai-thai-thong-tin-chi-tiet-chi-phi-xay-dung-nha-mai-thai-va-29-mau-nha-mai-thai-dep-me-ly",
   },
   {
      name: "mẫu nhà mái Nhật",
      slug: "/blog/nguon-cam-hung/top-29-mau-nha-mai-nhat-dep-kho-cuong-cho-gia-chu-2023",
   },
   {
      name: "mẫu nhà cấp 4 đẹp",
      slug: "/blog/nguon-cam-hung/top-29-mau-nha-cap-4-dep-me-ly-van-nguoi-me",
   },
   {
      name: "mẫu nhà ống đẹp",
      slug: "/blog/nguon-cam-hung/top-19-mau-thiet-ke-noi-that-nha-ong-2023-dep-me-ly",
   },
   { name: "công ty nội thất", slug: "/cac-cong-ty-noi-that" },
   { name: "báo giá thi công", slug: "/bao-gia" },
   {
      name: "chi phí thiết kế nội thất",
      slug: "/blog/kien-thuc-noi-that/chi-phi-thiet-ke-noi-that-chi-tiet-can-biet-khi-lam-nha",
   },
   {
      name: "phong cách retro",
      slug: "/blog/phong-cach-noi-that/phong-cach-noi-that-retro-khong-gian-hien-dai-mang-dau-an-hoai-co",
   },
   {
      name: "phong cách wabi sabi",
      slug: "/blog/phong-cach-noi-that/phong-cach-wabi-sabi-net-tham-my-cua-su-khong-hoan-hao",
   },
   {
      name: "phong cách indochine",
      slug: "/blog/phong-cach-noi-that/phong-cach-indochine-dong-duong-la-gi",
   },
   {
      name: "phong cách scandinavian",
      slug: "/blog/phong-cach-noi-that/scandinavian-phong-cach-noi-that-thinh-hanh",
   },
   {
      name: "phong cách tối giản",
      slug: "/blog/phong-cach-noi-that/phong-cach-thiet-ke-noi-that-minimalist",
   },
   {
      name: "vật liệu gỗ",
      slug: "/blog/kien-thuc-noi-that/go-cong-nghiep-dac-diem-va-bang-gia-cac-loai-go-cong-nghiep-pho-bien-hien-nay",
   },
   {
      name: "phong cách hiện đại",
      slug: "/blog/phong-cach-noi-that/phong-cach-thiet-ke-noi-that-hien-dai",
   },
];

interface Props {
   brands: Brand[];
   menulv2: MenuItem[];
   dataOfSSRHinhAnhNoiThat: any;
   dataOfSSRThuVienThietKe: any;
   dataOfSSRCongTrinhThucTe: any;
   dataOfSSRToAmCuaToi: any;
}

const ContentEndPage: React.FC<Props> = ({
   brands,
   menulv2,
   dataOfSSRHinhAnhNoiThat,
   dataOfSSRThuVienThietKe,
   dataOfSSRCongTrinhThucTe,
   dataOfSSRToAmCuaToi,
}) => {
   // debugger;
   const [loadMore, setLoadMore] = useState<boolean>(false);

   const loadMoreContent = () => setLoadMore((prev) => !prev);

   useEffect(() => {
      if (typeof window !== "undefined") {
         setLoadMore(true);
      }
   }, []);

   return (
      <div
         className="mt-md-5"
         style={isMobile ? { paddingLeft: "10px", paddingRight: "10px" } : { paddingLeft: "0px", paddingRight: "0px" }}
      >
         <div className="content_end_page">
            {/* --- launch for SPACE T - NỘI THẤT TOÀN DIỆN */}
            <div className="content_item">
               <h2 className={styles["content_title"]}>SPACE T - NỘI THẤT TOÀN DIỆN</h2>
               <div className="content_description">
                  Space T là công ty công nghệ hoạt động trong lĩnh vực nội thất, xây dựng. Space T cung cấp toàn diện
                  dịch vụ thiết kế, thi công và nội thất, đáp ứng nhu cầu của bạn với chất lượng cao nhất. Chúng tôi ra
                  đời với sứ mệnh giúp các gia chủ Việt dễ dàng, nhanh chóng hoàn thiện ngôi nhà trong mơ.
                  {loadMore && (
                     <span className="btn_see_more see_more" onClick={loadMoreContent}>
                        Xem thêm
                     </span>
                  )}
               </div>
            </div>
            {/* --- finished for SPACE T - NỘI THẤT TOÀN DIỆN */}

            <div className={classNames(["more_content"], [loadMore ? ["see_more"] : "see_less"])}>
               {/* --- launch for THIẾT KẾ THI CÔNG */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>THIẾT KẾ THI CÔNG</h2>
                  <div className="content_description">
                     Space T cung cấp toàn diện dịch vụ thiết kế và thi công nội thất. Bạn có thể tham khảo chi tiết về{" "}
                     <a href="quy-trinh-ket-noi">quy trình hoàn thiện nội thất</a> qua Space T.
                  </div>
                  <div className="content_description">
                     <a className="fw-bold" href="/thu-vien-anh" title="Thư viện ảnh">
                        Thư viện ảnh
                     </a>{" "}
                     - Tổng hợp {dataOfSSRHinhAnhNoiThat?.totalDocs} hình ảnh nội thất đẹp theo các khu vực, không gian
                     phòng trong nhà.
                  </div>
                  <div className="content_description mt-2">
                     <strong>Dự án hoàn thiện</strong> - Tổng hợp{" "}
                     <a href="/thu-vien-thiet-ke" title="Mẫu thiết kế">
                        {dataOfSSRThuVienThietKe?.paginate?.totalDocs} Mẫu thiết kế{" "}
                     </a>
                     concept nội thất,{" "}
                     <a href="/cong-trinh-thuc-te" title="Công trình thực tế">
                        {dataOfSSRCongTrinhThucTe?.paginate?.totalDocs} Công trình thực tế{" "}
                     </a>
                     hoàn thiện và{" "}
                     <a href="/to-am-cua-toi" title="Tổ ấm của tôi">
                        {dataOfSSRToAmCuaToi?.paginate?.totalDocs} chia sẻ về Tổ ấm của tôi.
                     </a>
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/bao-gia" title="Tính chi phí">
                        Tính chi phí
                     </a>{" "}
                     - Công cụ tính chi phí làm nội thất cho gia chủ cực tiện lợi và sát với chi phí thực tế.
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/thiet-ke-2d-3d" title="Thiết kế 3D">
                        Thiết kế 3D
                     </a>{" "}
                     - Công cụ để bạn tự tay thiết kế không gian nội thất 2D/3D online.
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/blog" title="Kiến thức">
                        Kiến thức
                     </a>{" "}
                     - Thư viện bài viết kiến thức, cảm hứng thiết kế và mẹo hay về nội thất - xây dựng.
                  </div>
               </div>
               {/* --- finished for THIẾT KẾ THI CÔNG */}

               {/* --- launch for TOP TỪ KHÓA XU HƯỚNG */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>TOP TỪ KHÓA XU HƯỚNG</h2>
                  <div className="content_description">
                     {KEYWORDS.map((e, i: number) => {
                        return (
                           <a title={e.name ?? ""} href={e.slug} key={i} className="mb-2">
                              {e.name}
                              {i < KEYWORDS.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                           </a>
                        );
                     })}
                     {!loadMore && (
                        <span className="btn_hide_see_more" onClick={loadMoreContent}>
                           <span> </span>Ẩn bớt
                        </span>
                     )}
                  </div>
               </div>
               {/* --- finished for TOP TỪ KHÓA XU HƯỚNG */}

               {/* --- launch for MUA SẮM NỘI THẤT CHÍNH HÃNG */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>MUA SẮM NỘI THẤT CHÍNH HÃNG</h2>
                  <div className="content_description">
                     Mua sắm online hơn 8000+ đồ nội thất, trang trí, gia dụng chính hãng đến từ 50+ thương hiệu với
                     nhiều ưu đãi hấp dẫn. Dễ dàng tìm kiếm và lựa chọn những món đồ ưng ý cho mọi không gian nội thất.
                     Ngoài ra, bạn có thể đăng ký tài khoản, tham gia đăng bài Social feed, review sản phẩm,... để nhận
                     <Link title="Space T Xu" href="/chinh-sach/chinh-sach-diem">
                        {" "}
                        Space T Xu
                     </Link>{" "}
                     và sử dụng để giảm giá trực tiếp cho các đơn hàng mua sắm nội thất. <br />
                     <div>
                        {menulv2 &&
                           menulv2?.map((e, i: number) => {
                              return (
                                 <Link href={`/shop/${e.info.slug}`} title={e.info.title} key={i} className="mb-2">
                                    {e.info.title}
                                    {i < menulv2?.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                                 </Link>
                              );
                           })}
                     </div>
                  </div>
               </div>
               {/* --- finished for MUA SẮM NỘI THẤT CHÍNH HÃNG */}

               {/* --- launch for TOP THỨNG HIỆU NỔI BẬT */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>TOP THƯƠNG HIỆU NỔI BẬT</h2>
                  <div className="content_description">
                     {brands?.map((e: Brand, i: number) => {
                        return (
                           <Link
                              title={e.info.name ?? ""}
                              href={`/shop/thuong-hieu/${e.info.slug}`}
                              key={i}
                              className="mb-2"
                           >
                              {e.info.name}
                              {i < brands?.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                           </Link>
                        );
                     })}
                  </div>
               </div>
               {/* --- finished for TOP THỨNG HIỆU NỔI BẬT */}

               {/* --- launch for SPACE T FEED */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>SPACE T FEED</h2>
                  <div className="content_description">
                     <a className="fw-bold" href="/feed" title="Space T Feed">
                        Space T Feed
                     </a>{" "}
                     là mạng xã hội Facebook thu nhỏ, là cộng đồng dành cho tín đồ yêu thích nội thất, gia chủ, chủ đầu
                     tư và chuyên gia nội thất với đa dạng nội dung, dễ dàng tìm hiểu, chia sẻ kiến thức, kinh nghiệm,
                     cảm hứng nội thất.
                  </div>
               </div>
               {/* --- finished for SPACE T FEED */}

               {/* --- launch for TẢI ỨNG DỤNG SPACE T */}
               <div className="content_item">
                  <h2 className={styles["content_title"]}>TẢI ỨNG DỤNG SPACE T</h2>
                  <div className="content_description">
                     Ứng dụng Space T cho phép bạn trải nghiệm đầy đủ những tính năng tiện lợi dành cho người yêu thích
                     nội thất, chuyên gia nội thất và chủ nhà có nhu cầu hoàn thiện nội thất:
                  </div>
                  <ul>
                     <li>
                        <div className="content_description">
                           Xem, tương tác, lưu trữ và chia sẻ ý tưởng, câu chuyện, hình ảnh, kiến thức từ cộng đồng
                           người yêu nội thất và chuyên gia nội thất.
                        </div>
                     </li>
                     <li>
                        <div className="content_description">
                           Tham khảo thông tin công trình thi công thực tế, mẫu thiết kế, dễ dàng gửi yêu cầu, nhận tư
                           vấn và báo giá làm nội thất nhanh chóng, miễn phí.
                        </div>
                     </li>
                     <li>
                        <div className="content_description">
                           Mua sắm đồ nội thất, trang trí, gia dụng online tiện lợi với thao tác đơn giản cùng nhiều ưu
                           đãi hấp dẫn.
                        </div>
                     </li>
                  </ul>
               </div>
               {/* --- finished for TẢI ỨNG DỤNG SPACE T */}
            </div>
         </div>
      </div>
   );
};
export default ContentEndPage;
