import Image from "next/image";
import classNames from "classnames";
import React, {
   PropsWithChildren,
   ReactNode,
   useEffect,
   useState,
} from "react";
import UserBookmark, {
   EUserBookmarkTypes,
   IUserBookmark,
} from "../../models/userBookmark.model";
import { ModelError } from "../../models/model";
import Bookmark from "../modal/Bookmark";
import { tokenKey } from "models/__variables";
import store from "state";
import { toggleModalAuth } from "state/action/auth";
import { EAuthType } from "@Components/modal/auth/const";

interface IProps extends PropsWithChildren {
   className?: string;
   defaultValue?: IUserBookmark;
   id: string;
   type: EUserBookmarkTypes;
   variant?: "primary" | "secondary";
   showNotification?: boolean;
   onChangeNotification?: ({
      code,
      show,
   }: {
      code: number;
      show: boolean;
   }) => void;
   onChangeUserBookmark?: (
      action: "create" | "delete",
      userBookmark: IUserBookmark
   ) => void;
   iconBookmark?: string | ReactNode;
   iconBookmarked?: string | ReactNode;
   classNameIcon?: string;
}

export default function BookmarkPost({
   className,
   defaultValue,
   id,
   type,
   variant = "primary",
   showNotification,
   onChangeNotification,
   children,
   ...props
}: IProps) {
   const [bookmarkStatus, setBookmarkStatus] = useState<
      IUserBookmark | undefined
   >();
   const [notification, setNotification] = useState<{
      code: number;
      show: boolean;
   }>();

   useEffect(() => {
      if (notification) {
         onChangeNotification && onChangeNotification(notification);
      }
   }, [notification]);

   useEffect(() => {
      setBookmarkStatus(defaultValue);
   }, [defaultValue]);

   const handleBookmark = async () => {
      try {
         if (bookmarkStatus) {
            const unBookmark = await UserBookmark.unBookmark(
               bookmarkStatus._id
            );
            if (unBookmark instanceof ModelError) {
               setNotification({
                  code: unBookmark.httpCode,
                  show: true,
               });
               throw Error(unBookmark.message);
            }
            setNotification({
               code: 999,
               show: true,
            });
            props.onChangeUserBookmark &&
               props.onChangeUserBookmark("delete", bookmarkStatus);
            setBookmarkStatus(undefined);
            return;
         } else {
            if (id) {
               let userBookmark: any;
               userBookmark = await UserBookmark.createUserBookmark(id, type);

               if (userBookmark instanceof ModelError) {
                  setNotification({
                     code: userBookmark.httpCode,
                     show: true,
                  });
                  throw Error(userBookmark.message);
               }
               setNotification({
                  code: 200,
                  show: true,
               });
               props.onChangeUserBookmark &&
                  props.onChangeUserBookmark("create", userBookmark);
               setBookmarkStatus(userBookmark);
               return;
            }
         }
      } catch (error) {
      } finally {
      }
   };

   const handleToggleBookmark = async (code: number, show: boolean) => {
      setNotification({
         code,
         show,
      });
   };

   if (props.iconBookmark && props.iconBookmarked) {
      return (
         <>
            <div
               id="bookmark-icon"
               className={classNames("bookmark-icon-style", className)}
               onClick={(e) => {
                  e.preventDefault();
                  const token = localStorage.getItem(tokenKey);
                  if (token) {
                     handleBookmark();
                  } else {
                     store.dispatch(
                        toggleModalAuth({
                           visible: true,
                           type: EAuthType.signup,
                        })
                     );
                  }
               }}
            >
               {bookmarkStatus ? props.iconBookmarked : props.iconBookmark}
            </div>
            {showNotification ? (
               <Bookmark
                  show={notification?.show}
                  notification={+notification?.code!}
                  handleClose={() => handleToggleBookmark(0, false)}
                  {...props}
               />
            ) : undefined}
         </>
      );
   }

   if (variant === "primary") {
      return (
         <>
            <div
               id="bookmark-icon"
               className={classNames("bookmark-icon-style", className)}
               onClick={(e) => {
                  e.preventDefault();
                  const token = localStorage.getItem(tokenKey);
                  if (token) {
                     handleBookmark();
                  } else {
                     store.dispatch(
                        toggleModalAuth({
                           visible: true,
                           type: EAuthType.signup,
                        })
                     );
                  }
               }}
            >
               {bookmarkStatus ? (
                  <svg
                     className={"b-icon"}
                     width="32"
                     height="32"
                     viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M22 23.5V9.5C22 8.94772 21.5523 8.5 21 8.5H11C10.4477 8.5 10 8.94772 10 9.5V23.5L16.0002 18.0744L22 23.5Z"
                        fill="#FF6347"
                        stroke="#FF6347"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               ) : (
                  <svg
                     className={"b-icon"}
                     width="32"
                     height="32"
                     viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <g filter="url(#filter0_bd_4130_23456)">
                        <path
                           d="M22 23.5V9.5C22 8.94772 21.5523 8.5 21 8.5H11C10.4477 8.5 10 8.94772 10 9.5V23.5L16.0002 18.0744L22 23.5Z"
                           fill="#252A2B"
                           fillOpacity="0.07"
                           shapeRendering="crispEdges"
                        />
                        <path
                           d="M22 23.5V9.5C22 8.94772 21.5523 8.5 21 8.5H11C10.4477 8.5 10 8.94772 10 9.5V23.5L16.0002 18.0744L22 23.5Z"
                           stroke="white"
                           strokeWidth="2"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           shapeRendering="crispEdges"
                        />
                     </g>
                     <defs>
                        <filter
                           id="filter0_bd_4130_23456"
                           x="5"
                           y="3.5"
                           width="22"
                           height="25"
                           filterUnits="userSpaceOnUse"
                           colorInterpolationFilters="sRGB"
                        >
                           <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                           />
                           <feGaussianBlur
                              in="BackgroundImageFix"
                              stdDeviation="2"
                           />
                           <feComposite
                              in2="SourceAlpha"
                              operator="in"
                              result="effect1_backgroundBlur_4130_23456"
                           />
                           <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                           />
                           <feOffset />
                           <feGaussianBlur stdDeviation="1" />
                           <feComposite in2="hardAlpha" operator="out" />
                           <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                           />
                           <feBlend
                              mode="normal"
                              in2="effect1_backgroundBlur_4130_23456"
                              result="effect2_dropShadow_4130_23456"
                           />
                           <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect2_dropShadow_4130_23456"
                              result="shape"
                           />
                        </filter>
                     </defs>
                  </svg>
               )}
            </div>
            {showNotification ? (
               <Bookmark
                  show={notification?.show}
                  notification={+notification?.code!}
                  handleClose={() => handleToggleBookmark(0, false)}
                  {...props}
               />
            ) : undefined}
         </>
      );
   }

   return (
      <>
         <Image
            id="bookmark-icon"
            height={24}
            width={24}
            className={classNames(
               ["bookmark-icon-style", "bookmark-icon__secondary"],
               className
            )}
            onClick={(e) => {
               e.preventDefault();
               const token = localStorage.getItem(tokenKey);
               if (token) {
                  handleBookmark();
               } else {
                  store.dispatch(
                     toggleModalAuth({ visible: true, type: EAuthType.signup })
                  );
               }
            }}
            src={`${
               bookmarkStatus
                  ? "/assets/img/icon/bookmark-active-border.svg"
                  : "/assets/img/icon/bookmark-dark-border.svg"
            }`}
            alt="icon-bookmark"
         />
         {showNotification ? (
            <Bookmark
               show={notification?.show}
               notification={+notification?.code!}
               handleClose={() => handleToggleBookmark(0, false)}
               {...props}
            />
         ) : undefined}
      </>
   );
}
