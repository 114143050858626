/* eslint-disable max-len */
import React, { useEffect, useRef } from "react";
import Image from "next/image";
import Slider from "react-slick";
import Link from "@Components/Link";
import Banner from "@Models/banner.model";

interface IProps {
   data: Banner[];
   bannerRight?: Banner[];
}

const Banners: React.FC<IProps> = ({ data, bannerRight }) => {
   const sliderRef = useRef<any>();

   useEffect(() => {
      setTimeout(() => {
         sliderRef.current?.slickGoTo(0); // Đảm bảo slider về vị trí đầu
      }, 100);
   }, []);

   const setPreviousURL = (banner: any) => {
      if (typeof window !== "undefined" && banner?.url?.includes("/cau-hoi-khao-sat")) {
         sessionStorage.setItem("previous_url", window.location.href);
      }
   };

   return (
      <div className="page-banner">
         <div className="slider">
            <Slider
               adaptiveHeight={true}
               arrows
               dots={false}
               speed={500}
               infinite={true}
               slidesToShow={1}
               slidesToScroll={1}
               autoplay={true}
               autoplaySpeed={6000}
               prevArrow={
                  <div>
                     <span className="slick-arrow-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.9393 17.0607C10.3536 16.4749 10.3536 15.5251 10.9393 14.9393L18.9393 6.93934C19.5251 6.35355 20.4749 6.35356 21.0607 6.93934C21.6464 7.52513 21.6464 8.47488 21.0607 9.06066L14.1213 16L21.0607 22.9393C21.6464 23.5251 21.6464 24.4749 21.0607 25.0607C20.4749 25.6464 19.5251 25.6464 18.9393 25.0607L10.9393 17.0607Z"
                              fill="white"
                           />
                        </svg>
                     </span>
                  </div>
               }
               nextArrow={
                  <div>
                     <span className="slick-arrow-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M21.0607 14.9393C21.6464 15.5251 21.6464 16.4749 21.0607 17.0607L13.0607 25.0607C12.4749 25.6464 11.5251 25.6464 10.9393 25.0607C10.3536 24.4749 10.3536 23.5251 10.9393 22.9393L17.8787 16L10.9393 9.06066C10.3536 8.47487 10.3536 7.52513 10.9393 6.93934C11.5251 6.35355 12.4749 6.35355 13.0607 6.93934L21.0607 14.9393Z"
                              fill="white"
                           />
                        </svg>
                     </span>
                  </div>
               }
            >
               {data?.length > 0 &&
                  data.map((banner, i) => {
                     return (
                        <div className="slider-item" key={i}>
                           {banner.reaction && (
                              <Link
                                 title="Banner"
                                 href={banner.reaction}
                                 className="link-banner"
                                 onClick={() => setPreviousURL(banner)}
                              ></Link>
                           )}
                           <picture>
                              <source media="(min-width: 768px)" srcSet={banner.mediaUrl ?? ""} />
                              <source media="(max-width: 767px)" srcSet={banner.mediaUrl ?? ""} />
                              <Image
                                 width="500"
                                 height="200"
                                 title={banner?.meta?.title}
                                 alt={banner?.meta?.altText || "Sapce T"}
                                 src={banner.mediaUrl ?? ""}
                              />
                           </picture>
                        </div>
                     );
                  })}
            </Slider>
         </div>
         {bannerRight?.[0]?.mediaUrl && (
            <Link className="right-banner" href={bannerRight?.[0]?.reaction}>
               <img src={bannerRight?.[0]?.mediaUrl} alt="Space T" />
            </Link>
         )}
      </div>
   );
};

export default Banners;
