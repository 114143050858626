export const menuCategories = [
   {
      title: "Tư vấn miễn phí",
      icon: "/assets/img/menu/advise.png",
      iconLg: "/assets/img/menu/advise.png",
      path: "/cau-hoi-khao-sat",
   },
   {
      title: "Dự toán miễn phí",
      icon: "/assets/img/menu/calculate.png",
      iconLg: "/assets/img/menu/calculate.png",
      path: "/bao-gia",
   },
   {
      title: "Thiết kế 2D/3D\nmiễn phí",
      icon: "/assets/img/menu/2d-3d.png",
      iconLg: "/assets/img/menu/2d-3d.png",
      path: "/thiet-ke-2d-3d",
   },
   {
      title: "Mua hàng giá sỉ",
      icon: "/assets/img/menu/bulk-order.png",
      iconLg: "/assets/img/menu/bulk-order.png",
      path: "/noithatgiasi",
   },
   {
      title: "Hình ảnh nội thất",
      icon: "/assets/img/menu/library.png",
      iconLg: "/assets/img/menu/library.png",
      path: "/thu-vien-anh",
   },
   {
      title: "Dự án\nhoàn thiện",
      icon: "/assets/img/menu/project-finish.png",
      iconLg: "/assets/img/menu/project-finish.png",
      path: "/thu-vien-thiet-ke",
   },
   {
      title: "Flash sale",
      icon: "/assets/img/menu/flash-sale.png",
      iconLg: "/assets/img/menu/flash-sale.png",
      path: "/shop/flash-sale",
   },
   {
      title: "Sản phẩm\nbán chạy",
      icon: "/assets/img/menu/best-seller.png",
      iconLg: "/assets/img/menu/best-seller.png",
      path: "/shop/san-pham-ban-chay",
   },
];
