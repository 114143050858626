/* eslint-disable max-len */
import React from "react";
import Slider, { Settings } from "react-slick";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import Ava1 from "../images/avatar1.png";
import Ava2 from "../images/avatar2.png";
import Ava3 from "../images/avatar3.png";
import styles from "./KhachHangReview.module.scss";

interface ICustomerReview {
   imgSrcBefore: string;
   avatar: string;
   name: string;
   address: string;
   rating: number;
   review: string;
   des?: string;
   date: string;
}

interface IState {
   activeItem: number;
}

const CustomNextArrow = (props) => (
   <div {...props} className="custom-arrow custom-next">
      <ArrowForwardIos />
   </div>
);

const CustomPrevArrow = (props) => (
   <div {...props} className="custom-arrow custom-prev">
      <ArrowBackIosNew />
   </div>
);

const customerReview: ICustomerReview[] = [
   {
      imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction1_v1.webp",
      avatar: Ava2.src,
      name: "Trần Thị Ngọc Nhi",
      address: "Eco Green Saigon",
      rating: 5,
      review: `Vì hiếm khi cần cải tạo hay thiết kế nội thất cho căn hộ nên chi phí luôn là mối bận tâm lớn nhất của mình. Thật may, khi biết đến Space T, mình đã được tư vấn rất kỹ về mức chi phí phải bỏ ra.`,
      date: "Hoàn thiện tháng 07/2022",
   },
   {
      imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction2_v1.webp",
      avatar: Ava1.src,
      name: "Nguyễn Đỗ Thu Uyên",
      address: "Vinhomes Grand Park",
      rating: 4.5,
      review: `Là một phụ nữ, tôi không quen với việc phải đi tìm nhà thầu hay quy trình làm việc với nhà thầu. Rất may khi tìm được Space T.`,
      date: "Hoàn thiện tháng 02/2023",
   },
   {
      imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction3_v1.webp",
      avatar: Ava3.src,
      name: "Đỗ Anh Quân",
      address: "WestGate Bình Chánh",
      rating: 4.8,
      review: `SpaceT đã giúp tôi thiết kế và thi công lại toàn bộ căn nhà với chi phí vô cùng hợp lý.`,
      date: "Hoàn thiện tháng 03/2024",
   },
];

export default class KhachHangReview extends React.Component<{}, IState> {
   constructor(props: {}) {
      super(props);
      this.state = {
         activeItem: 0,
      };
   }

   handleStepChange = (item: number) => {
      this.setState({ activeItem: item });
   };

   getSliderSettings(): Settings {
      return {
         dots: false,
         arrows: false,
         infinite: false,
         autoplay: false,
         cssEase: "linear",
         slidesToShow: 3,
         slidesToScroll: 3,
         nextArrow: <CustomNextArrow />,
         prevArrow: <CustomPrevArrow />,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2.1,
                  slidesToScroll: 2.1,
                  dots: true,
               },
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1.1,
                  slidesToScroll: 1.1,
                  dots: true,
               },
            },
         ],
      };
   }

   renderReviewItem = (item: ICustomerReview, i: number) => (
      <div className={styles["slider-item"]} key={i}>
         <div className={styles["img"]}>
            <div className={styles["wrap-avatar"]}>
               <img src={item.avatar} alt={item.name} />
            </div>
            <img src={item.imgSrcBefore} alt="Phản hồi từ khách hàng" className={styles["user-img"]} />
            <div className={styles["cover"]}></div>
         </div>
         <div className={styles["review-detail"]}>
            <div className={styles["review-detail__title"]}>{item.name}</div>
            <div className={styles["review-detail__sub-title"]}>{item.address}</div>
            <div className={styles["review-detail__rating"]}>
               <img src="/assets/img/cover/ful-star.svg" alt="rating" />
               <span>{item.rating}/5</span>
            </div>
            <div className={styles["review-detail__des"]}>{item.review}</div>
            <p className={styles["review-detail__date"]}>{item.date}</p>
         </div>
      </div>
   );

   render() {
      return (
         <div className={styles["block"]}>
            <div className={styles["block-sub"]}>
               <h2 className={styles["main_title"]}>Khách hàng nói gì về Space T</h2>
            </div>
            <div className="review">
               <div className="slider-wrap">
                  <Slider {...this.getSliderSettings()}>{customerReview.map(this.renderReviewItem)}</Slider>
               </div>
            </div>
         </div>
      );
   }
}
