import classNames from "classnames";
import { useRouter } from "next/router";
import { SetStateAction, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import User from "models/user.model";
import Model, { ModelError } from "models/model";
import MarketingAds, { EMarketingAds } from "models/marketingAds";
import { RootState } from "state";

interface ICacheAds {
   animationText?: { [t: string]: boolean };
   floating?: { [t: string]: boolean };
   popup?: { [t: string]: boolean };
}

interface IState {
   showPopup: boolean;
   showFloating: boolean;
   showMessing: boolean;

   animationText?: any;
   floating?: MarketingAds;
   popup?: MarketingAds;
}

export default function DialogAds({ page }: { page?: string }) {
   const router = useRouter();
   const { user } = useSelector((state: RootState) => state.auth) as { user?: User };
   const [state, setstate] = useState<IState>({
      showPopup: false,
      showFloating: false,
      showMessing: false,
   });
   function setState(newS: Partial<IState> | SetStateAction<IState>) {
      if (typeof newS === "function") {
         setstate(newS);
      }
      setstate((prev) => ({ ...prev, ...newS }));
   }

   const cacheKey = "spt:ads" + (page ? page : "");

   useEffect(() => {
      MarketingAds.getPopup().then((res) => {
         if (res instanceof ModelError) return;
         setState({
            popup: res.popup,
            animationText: res.animationText,
            floating: res.floating,
         });

         if (!isMobile) {
            if (res.popup && res.floating) {
               const wiShowPopup = detectShowPopup(res.popup);
               if (!wiShowPopup) {
                  detectShowFloating(res.floating);
               }
            } else if (res.popup) {
               detectShowPopup(res.popup);
            } else if (res.floating) {
               detectShowFloating(res.floating);
            }
            return;
         }

         setState({
            popup: res.popup,
            animationText: res.animationText,
            floating: res.floating,
         });

         if (res.popup && res.animationText && res.floating) {
            const wiShowPopup = detectShowPopup(res.popup);
            if (!wiShowPopup) {
               const wiShowText = detectShowAnimationText(res.animationText);
               if (wiShowText) {
                  setTimeout(() => {
                     setState({ showMessing: false });
                     detectShowFloating(res.floating);
                  }, 3000);
               } else {
                  detectShowFloating(res.floating);
               }
            }
         } else if (res.popup && res.animationText) {
            const wiShowPopup = detectShowPopup(res.popup);
            if (!wiShowPopup) {
               const wiShowText = detectShowAnimationText(res.animationText);
               if (wiShowText) {
                  setTimeout(() => {
                     setState({ showMessing: false });
                  }, 3000);
               }
            }
         } else if (res.popup && res.floating) {
            const wiShowPopup = detectShowPopup(res.popup);
            if (!wiShowPopup) {
               detectShowAnimationText(res.animationText);
            }
         } else if (res.animationText && res.floating) {
            const wiShowText = detectShowAnimationText(res.animationText);
            if (wiShowText) {
               setTimeout(() => {
                  setState({ showMessing: false });
                  detectShowFloating(res.floating);
               }, 3000);
            } else {
               detectShowFloating(res.floating);
            }
         } else if (res.popup) {
            detectShowPopup(res.popup);
         } else if (res.floating) {
            detectShowFloating(res.floating);
         }
      });
   }, []);

   function getCache() {
      const cache = JSON.parse(localStorage.getItem(cacheKey) as any) as ICacheAds;
      // const cache = {
      //    popup: {
      //       ["66581d6ebcae4f47a7799ab1"]: false,
      //    },
      //    animationText: {
      //       ["66581d75bcae4f47a7799ab5"]: false,
      //    },
      //    floating: {
      //       ["66581d7fbcae4f47a7799ab9"]: false,
      //    },
      // };
      return cache;
   }

   function detectShowPopup(popup?: MarketingAds) {
      if (!popup) return;

      const cache = getCache();
      if (cache && cache?.popup?.[popup?._id]) {
      } else {
         setState({ showPopup: true });
         return true;
      }
   }
   function detectShowFloating(floating?: MarketingAds) {
      if (!floating) return;

      const cache = getCache();

      if (cache && cache?.floating?.[floating?._id]) {
      } else {
         setState({ showFloating: true });
         return true;
      }
   }
   function detectShowAnimationText(animationText?: MarketingAds) {
      if (!animationText) return;

      const cache = getCache();

      if (cache && cache?.animationText?.[animationText?._id]) {
      } else {
         setState({ showMessing: true });
         return true;
      }
   }

   function handleClosePopup() {
      setState({ showPopup: false });
      // set cache it showed
      const cache = getCache();

      localStorage.setItem(
         cacheKey,
         JSON.stringify({
            ...cache,
            popup: {
               ...cache?.popup,
               [state.popup?._id!]: true,
            },
         })
      );

      if (isMobile) {
         if (state.animationText) {
            const wiShow = detectShowAnimationText(state.animationText);
            if (wiShow) {
               setTimeout(() => {
                  setState({ showMessing: false });
                  detectShowFloating(state.floating);
               }, 3000);
            }
         } else if (state.floating) {
            detectShowFloating(state.floating);
         }
      } else {
         if (state.floating) {
            detectShowFloating(state.floating);
         }
      }
   }
   function handleCloseFloating() {
      setState({ showFloating: false });

      // set cache it showed
      const cache = getCache();

      localStorage.setItem(
         cacheKey,
         JSON.stringify({
            ...cache,
            floating: {
               ...cache?.floating,
               [state.floating?._id!]: true,
            },
         })
      );
   }

   function handleClickPopupImage(section) {
      switch (section) {
         case EMarketingAds.actualWork:
            router.push("/cong-trinh-thuc-te");
            break;
         case EMarketingAds.designLibrary:
            router.push("/thu-vien-thiet-ke");
            break;
         case EMarketingAds.construction:
            router.push("/to-am-cua-toi");
            break;
         case EMarketingAds.shopHome:
            router.push("/shop");
            break;
         case EMarketingAds.pointPolicy:
            router.push("/chinh-sach/chinh-sach-diem");
            break;
         case EMarketingAds.flashSales:
            router.push("/shop/flash-sale");
            break;
         case EMarketingAds.bestSeller:
            router.push("/shop/san-pham-ban-chay");
            break;
         case EMarketingAds.newArrival:
            router.push("/shop/san-pham-moi");
            break;
         case EMarketingAds.feedHome:
            router.push("/feed");
            break;
         case EMarketingAds.blogHome:
            router.push("/blog");
            break;
         case EMarketingAds.libraryHome:
            router.push("/thu-vien-anh");
            break;
         case EMarketingAds.calculator:
            router.push("/bao-gia");
            break;
         case EMarketingAds.interiorFirm:
            router.push("/cac-cong-ty-noi-that");
            break;
         default:
            break;
      }
   }

   if (!state.popup && !state.floating && !state.animationText) return <></>;
   return (
      <>
         <div
            className={classNames("dialog-ads", state.showPopup ? "open" : "close")}
            style={{ display: state.showPopup ? "flex" : "none" }}
         >
            <div className="dialog-backdrop"></div>
            <div className="dialog-popup">
               <span className="close-icon" onClick={handleClosePopup}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect width="40" height="40" rx="20" fill="white" />
                     <path
                        d="M26.9844 14.4219L21.4062 20L26.9844 25.5781L25.5781 26.9844L20 21.4062L14.4219 26.9844L13.0156 25.5781L18.5938 20L13.0156 14.4219L14.4219 13.0156L20 18.5938L25.5781 13.0156L26.9844 14.4219Z"
                        fill="#252A2B"
                     />
                  </svg>
               </span>
               {state.popup ? (
                  <div className="wimg" onClick={() => handleClickPopupImage(state.popup?.section)}>
                     <img
                        className="img"
                        src={state.popup?.info?.$url}
                        title={state.popup?.meta?.title}
                        alt={state.popup?.meta?.description}
                     />
                  </div>
               ) : (
                  <div className="default-img">
                     <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
                        <path
                           d="M60.9375 116.667L22.3958 166.667H177.604L127.604 100L89.0625 150L60.9375 116.667ZM200 177.604C200 183.507 197.743 188.715 193.229 193.229C188.715 197.743 183.507 200 177.604 200H22.3958C16.4931 200 11.2847 197.743 6.77083 193.229C2.25694 188.715 0 183.507 0 177.604V22.3958C0 16.4931 2.25694 11.2847 6.77083 6.77083C11.2847 2.25694 16.4931 0 22.3958 0H177.604C183.507 0 188.715 2.25694 193.229 6.77083C197.743 11.2847 200 16.4931 200 22.3958V177.604Z"
                           fill="#E2E4EB"
                        />
                     </svg>
                  </div>
               )}
            </div>
         </div>
         <div
            className={classNames("floating-ads", state.showFloating ? "open" : "close")}
            style={{ display: state.showFloating ? "flex" : "none" }}
         >
            <svg
               className="close-icon"
               onClick={handleCloseFloating}
               width="40"
               height="40"
               viewBox="0 0 40 40"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <rect width="40" height="40" rx="20" fill="#FF6347" />
               <path
                  d="M26.9844 14.4219L21.4062 20L26.9844 25.5781L25.5781 26.9844L20 21.4062L14.4219 26.9844L13.0156 25.5781L18.5938 20L13.0156 14.4219L14.4219 13.0156L20 18.5938L25.5781 13.0156L26.9844 14.4219Z"
                  fill="white"
               />
            </svg>
            {state.floating ? (
               <div className="wimg" onClick={() => handleClickPopupImage(state.floating?.section)}>
                  <img
                     className="img"
                     src={state.floating?.info?.$url}
                     title={state.floating?.meta?.title}
                     alt={state.floating?.meta?.description}
                  />
               </div>
            ) : (
               <div className="default-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
                     <path
                        d="M60.9375 116.667L22.3958 166.667H177.604L127.604 100L89.0625 150L60.9375 116.667ZM200 177.604C200 183.507 197.743 188.715 193.229 193.229C188.715 197.743 183.507 200 177.604 200H22.3958C16.4931 200 11.2847 197.743 6.77083 193.229C2.25694 188.715 0 183.507 0 177.604V22.3958C0 16.4931 2.25694 11.2847 6.77083 6.77083C11.2847 2.25694 16.4931 0 22.3958 0H177.604C183.507 0 188.715 2.25694 193.229 6.77083C197.743 11.2847 200 16.4931 200 22.3958V177.604Z"
                        fill="#E2E4EB"
                     />
                  </svg>
               </div>
            )}
         </div>
         {isMobile && state.animationText && (
            <div className={classNames("text-ads ", state.showMessing ? "open" : "close")}>
               <div>
                  <a>
                     <div className="w-avatar">
                        {user ? (
                           <img src={user?.__media?.$url} alt="" />
                        ) : (
                           <img src={"/assets/img/cover/avatar.png"} alt="" />
                        )}
                     </div>
                  </a>
                  <div className="text-white fs-10 fw-400 lh-14 text">
                     {(state.animationText?.info as any)?.text}
                     <a href={state.animationText?.url} className="link-to">
                        Xem ngay!
                     </a>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
