import React from "react";
import styles from "./SpacetParner.module.scss";

const MENUS = [
   {
      title: "",
      icon: "/assets/img/partner/v2/google-mb.png",
      iconLg: "/assets/img/partner/v2/google.png",
      path: "",
   },
   {
      title: "",
      icon: "/assets/img/partner/v2/lotte-mb.png",
      iconLg: "/assets/img/partner/v2/lotte.png",
      path: "",
   },

   {
      title: "",
      icon: "/assets/img/partner/v2/fpt-mb.png",
      iconLg: "/assets/img/partner/v2/fpt.png",
      path: "",
   },
   {
      title: "",
      icon: "/assets/img/partner/v2/hutech-mb.png",
      iconLg: "/assets/img/partner/v2/hutech.png",
      path: "",
   },
   {
      title: "",
      icon: "/assets/img/partner/v2/naver-mb.png",
      iconLg: "/assets/img/partner/v2/naver.png",
      path: "",
   },

   {
      title: "",
      icon: "/assets/img/partner/v2/hana-mb.png",
      iconLg: "/assets/img/partner/v2/hana.png",
      path: "",
   },
];

const SpacetParner = () => {
   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["title"]}>Đối tác của Space T</h2>
         </div>
         <div className={styles["spacet-partner"]}>
            <div className="row gx-2 gy-2 gy-md-3 gx-md-3">
               {MENUS.map((item, index) => (
                  <div className="col-6 col-md-4" key={index}>
                     <div className={styles["partner-item"]}>
                        <div className="image">
                           <picture>
                              <source media="(min-width: 768px)" srcSet={item.iconLg || item.icon} />
                              <source media="(max-width: 767px)" srcSet={item.icon || ""} />
                              <img title={item.title || ""} alt={item.title || ""} src={item.iconLg || ""} />
                           </picture>
                           <div className="cover"></div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default SpacetParner;
