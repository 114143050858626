/* eslint-disable max-len */
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import { currency } from "utils";
import BookmarkPost from "@Components/bookmark/BookmarkPost";
import { ImageView } from "@Components/image";
import Product from "models/product.model";
import { EUserBookmarkTypes, IUserBookmark } from "models/userBookmark.model";
import Link from "@Components/Link";

interface IProps {
   type?: "primary" | "secondary";
   className?: string;
   showDes?: boolean;
   showBorder?: boolean;
   showBookmark?: boolean;
   fixedHeight?: boolean;
   product: Product;
   bookmark?: IUserBookmark;
   showProcessSelling?: boolean;
   onChangeNotification?: (arg: { code: number; show: boolean }) => void;
   onChangeUserBookmark?: (
      action: "create" | "delete",
      userBookmark: IUserBookmark
   ) => void;
   onAddToCart?: (product: Product) => void;
}

function CardProductV2({
   type = "primary",
   className,
   showDes = true,
   showBorder,
   showProcessSelling,
   showBookmark = true,
   fixedHeight,
   product,
   bookmark,
   onChangeNotification,
   onChangeUserBookmark,
   onAddToCart,
}: IProps) {
   if (!product) return <></>;

   return (
      <Link
         href={`/shop/${product.info.slug ?? ""}`}
         title={product.info.title}
      >
         <div
            className={classNames({
               "product-cardv2": true,
               [type]: true,
               [className || ""]: true,
               fixedHeight,
               showProcessSelling,
            })}
         >
            <div
               className={classNames({
                  "product-cardv2-info": true,
                  showBorder,
               })}
            >
               <div className="product-card-image">
                  <div
                     id="product-card"
                     className="product-card-link text-decoration-none position-relative test"
                  >
                     <div className="wrap-img">
                        <ImageView
                           width={100}
                           height={100}
                           src={
                              product.__images?.[0]
                                 ? product.__images[0].$url3And4ColResizeMax
                                 : ""
                           }
                           alt={product.info.title ?? ""}
                           title={product?.info?.title ?? ""}
                        />
                        {product?.isTaggingCampaign && (
                           <img
                              className="tag-campain"
                              src="/assets/img/product-tag-campain.png"
                              alt=""
                           />
                        )}
                        {!product?.isStocking && (
                           <div className="product-stock-out">Hết hàng</div>
                        )}
                     </div>
                  </div>
                  {!isMobile && showBookmark && (
                     <BookmarkPost
                        id={product._id}
                        type={EUserBookmarkTypes.product}
                        defaultValue={bookmark}
                        onChangeNotification={onChangeNotification}
                        onChangeUserBookmark={onChangeUserBookmark}
                     />
                  )}
                  {product.isSalesLabel && (
                     <div className="sale-label">
                        <svg
                           width="14"
                           height="12"
                           viewBox="0 0 14 12"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.7112 10.2396H5.26155C5.26732 10.2843 5.2703 10.33 5.2703 10.3764C5.2703 10.9051 4.88339 11.3337 4.40611 11.3337C3.92882 11.3337 3.54191 10.9051 3.54191 10.3764C3.54191 10.33 3.54489 10.2843 3.55066 10.2396H2.30734V9.69263V1.76101H0.825858C0.553125 1.76101 0.332031 1.51611 0.332031 1.214C0.332031 0.911896 0.553125 0.666992 0.825858 0.666992H2.80117H3.29499V9.14562H13.1715C13.4443 9.14562 13.6654 9.39053 13.6654 9.69263C13.6654 9.99474 13.4443 10.2396 13.1715 10.2396H12.422C12.4278 10.2843 12.4308 10.33 12.4308 10.3764C12.4308 10.9051 12.0439 11.3337 11.5666 11.3337C11.0893 11.3337 10.7024 10.9051 10.7024 10.3764C10.7024 10.33 10.7054 10.2843 10.7112 10.2396ZM4.28265 1.4875H10.7024C10.9751 1.4875 11.1962 1.73241 11.1962 2.03451V8.05161C11.1962 8.35371 10.9751 8.59862 10.7024 8.59862H4.28265C4.00992 8.59862 3.78882 8.35371 3.78882 8.05161V2.03451C3.78882 1.73241 4.00992 1.4875 4.28265 1.4875ZM6.25796 2.03451V4.22255L7.49545 3.67554L8.72709 4.22255V2.03451H6.25796Z"
                              fill="white"
                           />
                        </svg>
                        GIÁ SỈ
                     </div>
                  )}
               </div>
               {showDes && (
                  <div className="product-item-detail">
                     <div id="product-card" className="product-card-link">
                        <h3 className="truncate-line-2 name">
                           {product.info.title}
                        </h3>
                     </div>
                     {showProcessSelling && (
                        <div className="process-selling">
                           <span
                              className="process-filler"
                              style={{
                                 width: !product?.isStocking
                                    ? "100%"
                                    : product.__salesCount < 10
                                    ? "20%"
                                    : "95%",
                              }}
                           ></span>
                           <div className="process-text">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="8"
                                 height="10"
                                 viewBox="0 0 8 10"
                                 fill="none"
                              >
                                 <path
                                    d="M3.89887 0.144846L0.712842 5.97303C0.651564 6.0839 0.651029 6.2204 0.711435 6.3318C0.771841 6.4432 0.884162 6.51286 1.00667 6.5149H2.8215C3.20015 6.5149 3.5071 6.83568 3.5071 7.23138V9.63972C3.50752 9.79888 3.60757 9.939 3.75345 9.98474C3.89933 10.0305 4.05666 9.97104 4.14085 9.83841L7.27503 4.91938C7.34539 4.8104 7.35241 4.66973 7.29327 4.5537C7.23414 4.43767 7.11852 4.36525 6.99272 4.36546H5.20094C4.81911 4.36546 4.50958 4.04198 4.50958 3.64296V0.307409C4.49092 0.163989 4.39087 0.0467363 4.25666 0.0110096C4.12244 -0.024717 3.98091 0.0282273 3.89887 0.144846Z"
                                    fill="white"
                                 />
                              </svg>

                              <span className="txt text-white uppercase fs-8 fw-700 lh-11">
                                 {!product?.isStocking
                                    ? "ĐÃ BÁN HẾT"
                                    : product.__salesCount < 10
                                    ? "ĐANG BÁN CHẠY"
                                    : `ĐÃ BÁN ${product.__salesCount}`}
                              </span>
                           </div>
                        </div>
                     )}
                     <div className="w-price-wrap">
                        <div className="price-wrap">
                           {(() => {
                              const __variant =
                                 product?.__variant || product?.__variants?.[0];
                              if (!__variant?.info) {
                                 return (
                                    <span className="price text-danger">
                                       Liên hệ
                                    </span>
                                 );
                              }
                              if (
                                 !__variant?.info.price &&
                                 !__variant?.info.priceAfterDiscount
                              ) {
                                 return (
                                    <span className="price text-danger">
                                       Liên hệ
                                    </span>
                                 );
                              } else if (
                                 __variant?.info.price &&
                                 !__variant?.info.priceAfterDiscount
                              ) {
                                 return (
                                    <span className="price">
                                       {currency(__variant?.info.price || 0)}{" "}
                                       <span style={{ verticalAlign: "super" }}>
                                          đ
                                       </span>
                                    </span>
                                 );
                              } else {
                                 return (
                                    <>
                                       <span className="discount-price">
                                          {currency(
                                             __variant?.info
                                                .priceAfterDiscount || 0
                                          )}{" "}
                                          <span
                                             style={{ verticalAlign: "super" }}
                                          >
                                             đ
                                          </span>
                                       </span>
                                       <div className="wrap-real-price">
                                          <span className="percent-price">
                                             {__variant?.info.price &&
                                             __variant?.info
                                                .priceAfterDiscount ? (
                                                <div className="percen-discount">{`-${Math.round(
                                                   100 -
                                                      (__variant?.info
                                                         .priceAfterDiscount *
                                                         100) /
                                                         __variant?.info.price
                                                )}%`}</div>
                                             ) : (
                                                ""
                                             )}
                                          </span>
                                          <span className="real-price">
                                             {currency(
                                                __variant?.info.price || 0
                                             )}{" "}
                                             <span>đ</span>
                                          </span>
                                       </div>
                                    </>
                                 );
                              }
                           })()}
                        </div>
                        {onAddToCart && product?.__variant && (
                           <div
                              className="btn-add-to-card pointer mt-1 mt-md-0"
                              onClick={(e) => {
                                 e.preventDefault();
                                 onAddToCart(product);
                              }}
                           >
                              <svg
                                 width="32"
                                 height="32"
                                 viewBox="0 0 32 32"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <rect
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#FF6347"
                                 />
                                 <g clip-path="url(#clip0_9928_156009)">
                                    <path
                                       d="M13 19L19.5401 18.455C21.5865 18.2845 22.0458 17.8375 22.2726 15.7967L22.75 11.5M11.5 11.5H11.875M23.5 11.5H21.625M14.125 11.5H19.375M16.75 14.125V8.875M13 22C13 22.8284 12.3284 23.5 11.5 23.5C10.6716 23.5 10 22.8284 10 22C10 21.1716 10.6716 20.5 11.5 20.5C12.3284 20.5 13 21.1716 13 22ZM13 22H18.25M18.25 22C18.25 22.8284 18.9216 23.5 19.75 23.5C20.5784 23.5 21.25 22.8284 21.25 22C21.25 21.1716 20.5784 20.5 19.75 20.5C18.9216 20.5 18.25 21.1716 18.25 22ZM8.5 8.5H9.2245C9.93301 8.5 10.5506 8.96844 10.7224 9.6362L12.9539 18.3074C13.0667 18.7456 12.9702 19.2098 12.6912 19.5712L11.9741 20.5"
                                       stroke="white"
                                       strokeWidth="1.25"
                                       strokeLinecap="round"
                                    />
                                 </g>
                                 <defs>
                                    <clipPath id="clip0_9928_156009">
                                       <rect
                                          width="18"
                                          height="18"
                                          fill="white"
                                          transform="translate(7 7)"
                                       />
                                    </clipPath>
                                 </defs>
                              </svg>
                           </div>
                        )}
                     </div>
                  </div>
               )}
            </div>
         </div>
      </Link>
   );
}

export default CardProductV2;
